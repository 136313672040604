import React, { useState, useContext, useMemo, Fragment } from 'react';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import { ApellaContext } from '../../context/ApellaContext';
import { UrlContext } from '../../context/UrlContext';
import { StylingContext } from '../../context/StylingContext';
import { LoggingContext } from '../../context/LoggingContext';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from 'react-spinkit';
import { muiTableTextLabels } from '../../translation/Translation';
import RoomFunctions from './RoomFunctions';
import { useSnackbar } from 'notistack';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from '@material-ui/core';
import { getIdsToDelete } from '../../helper/getData';
import { deleteUsers } from '../../helper/getData';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AgentDrawer from '../drawer/AgentDrawer';
import DeleteRoom from '../dialogues/DeleteRoom';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff',
  },
  snackbar: {
    color: 'white',
    background: '#006c52',
  },
}));

export default function RoomsList(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [backdropOpen, setbackdropOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const data = useContext(ApellaContext);
  const urlData = useContext(UrlContext);
  const stylingData = useContext(StylingContext);
  const loggingData = useContext(LoggingContext);
  const [falseLogin, setfalseLogin] = useState(false);
  const [deleteRoomDialog, setdeleteRoomDialog] = useState(false);
  const [roomToDelete, setroomToDelete] = useState(null);

  async function getRoom(room) {
    setbackdropOpen(true);
    const postData = {
      agentData: {
        id: data.agentData.id,
      },
      clientData: {
        id: data.clientData.id,
      },
      consultationDate: room.consultationDate,
      contextID: data.contextID,
      companyIdentification: data.companyIdentification,
      otpKey: data.otpKey,
      stage: data.stage,
    };
    const response = await fetch(
      urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.getRoom.endpoint,
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          Authorization: `Bearer ${data.apiToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData), // body data type must match "Content-Type" header
      }
    );
    const json = await response.json();
    if (!response.ok) {
      setfalseLogin(true);
      enqueueSnackbar(`'Fehler!: ' + ${json.message}`, { variant: 'error' });
      console.log('Fehler!: ' + json.message);
      setbackdropOpen(false);
    } else {
      setfalseLogin(false);
      data.setagentData(json.Item.agentData);
      data.setclientData(json.Item.clientData);
      data.setroomData(json.Item.roomData);
      data.setDocuments(json.Item.documents);
      data.setcompanyIdentification(json.Item.companyIdentification);
      data.setcontextID(json.Item.contextID);
      data.setAgendaText(json.Item.agendaText);

      // Teilnehmer laden (neu)
      const participants = await fetch(
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.getUsers.endpoint,
        {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'omit',
          headers: {
            Authorization: `Bearer ${data.apiToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            agentId: json.Item.agentData.id,
            clientId: json.Item.clientData.id,
            consultationDate: room.consultationDate,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => response.Items)
        .then((participants) =>
          participants.map((participant) => ({
            firstName: '',
            lastName: '',
            email: '',
            ...participant,
          }))
        );
      data.setexperts(participants);

      setbackdropOpen(false);

      enqueueSnackbar(
        `Der Beratungsraum zum Thema ${json.Item.roomData.consultationTitle} für den ${
          json.Item.roomData.consultationDate.split('-')[0]
        } um ${json.Item.roomData.consultationTime} wurde geladen.`,
        { variant: 'success' }
      );
    }
  }

  const myDefaultOptions = {
    filter: false,
    sort: true,
    customBodyRender: (value, tableMeta, updateValue) => {
      if (
        tableMeta.tableState.expandedRows.data.findIndex((row) => {
          return row.index === tableMeta.rowIndex;
        }) !== -1
      ) {
        return <span style={{ fontWeight: 'bold' }}>{value}</span>;
      }
      return value;
    },
  };

  const columns = [
    {
      name: 'consultationTitle',
      label: 'Titel',
      options: myDefaultOptions,
    },
    {
      name: 'consultationDate',
      label: 'Datum',
      options: {
        sortCompare: (order) => {
          return (a, b) => {
            const dateAStringArray = a.data.split('.');
            const dateBStringArray = b.data.split('.');
            const dateA = new Date(
              dateAStringArray[2],
              dateAStringArray[1],
              dateAStringArray[0]
            ).getTime();
            const dateB = new Date(
              dateBStringArray[2],
              dateBStringArray[1],
              dateBStringArray[0]
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === 'desc' ? 1 : -1);
          };
        },
      },
    },
    {
      name: 'consultationTime',
      label: 'Uhrzeit',
      options: myDefaultOptions,
    },
    {
      name: 'processingStatus',
      label: 'Status',
      options: myDefaultOptions,
    },
    {
      name: 'Vorbereiten / Starten',
      options: {
        width: 20,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <IconButton
              aria-label="actions"
              onClick={async () => {
                await getRoom(filteredRoomsList[dataIndex].roomData);
                if (!falseLogin) {
                  setTimeout(() => setShowDialog(true), 100);
                }
                loggingData.setSequence({
                  type: 'openPreload',
                  agentData: {
                    id: data.agentData.id,
                    firstName: data.agentData.agentData.firstName,
                    lastName: data.agentData.agentData.lastName,
                  },
                  clientData: {
                    id: data.clientData.id,
                    firstName: data.clientData.clientData.firstName,
                    lastName: data.clientData.clientData.lastName,
                  },
                  consultationDate: data.roomData.consultationDate,
                  consultationTime: data.roomData.consultationTime,
                });
              }}
              color="primary"
            >
              <AssignmentOutlinedIcon /> / <PlayCircleOutlineIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: 'Starten',
      options: {
        width: 20,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <IconButton
              aria-label="actions"
              onClick={async () => {
                await getRoom(filteredRoomsList[dataIndex].roomData);
                if (!falseLogin) {
                  setTimeout(() => setShowDialog(true), 100);
                }
                loggingData.setSequence({
                  type: 'straitStart',
                  agentData: {
                    id: data.agentData.id,
                    firstName: data.agentData.agentData.firstName,
                    lastName: data.agentData.agentData.lastName,
                  },
                  clientData: {
                    id: data.clientData.id,
                    firstName: data.clientData.clientData.firstName,
                    lastName: data.clientData.clientData.lastName,
                  },
                  consultationDate: data.roomData.consultationDate,
                  consultationTime: data.roomData.consultationTime,
                });
                data.setPage(<AgentDrawer />);
              }}
              color="primary"
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: 'Löschen',
      options: {
        width: 20,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <IconButton
              aria-label="actions"
              onClick={() => {
                setdeleteRoomDialog(true);
                setroomToDelete({
                  roomName: filteredRoomsList[dataIndex].roomData.consultationTitle,
                  roomIndex: dataIndex,
                });
              }}
              /* onClick={() => handleDelete(dataIndex)} */
              color="secondary"
            >
              <DeleteForeverIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  const handleDelete = async (id) => {
    setbackdropOpen(true);
    const postData = {
      agentData: {
        id: data.agentData.id,
      },
      clientData: {
        id: data.clientData.id,
      },
      consultationDate: filteredRoomsList[id].roomData.consultationDate,
    };
    loggingData.setSequence({
      type: 'deleteRoom',
      agentData: {
        id: data.agentData.id,
        firstName: data.agentData.agentData.firstName,
        lastName: data.agentData.agentData.lastName,
      },
      clientData: {
        id: data.clientData.id,
        firstName: data.clientData.clientData.firstName,
        lastName: data.clientData.clientData.lastName,
      },
      consultationDate: filteredRoomsList[id].roomData.consultationDate,
      consultationTime: filteredRoomsList[id].roomData.consultationTime,
      consultationTitle: filteredRoomsList[id].roomData.consultationTitle,
    });
    await fetch(
      urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.deleteRoom.endpoint,
      {
        method: 'DELETE',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          Authorization: `Bearer ${data.apiToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      }
    );
    await getIdsToDelete({
      agentId: data.agentData.id,
      clientId: data.clientData.id,
      consultationDate: filteredRoomsList[id].roomData.consultationDate,
      apiToken: data.apiToken,
      urlParam:
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.getUsersToDelete.endpoint,
    }).then((response) => {
      if (response !== undefined && response !== '')
        response.data.forEach(async (element) => {
          await deleteUsers({
            password: element.password,
            apiToken: data.apiToken,
            urlParam:
              urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.deleteUsers.endpoint,
          });
        });
    });

    setbackdropOpen(false);
    props.onFetchRooms();
    enqueueSnackbar('Der Raum wurde erfolgreich gelöscht.');
  };

  const filteredRoomsList = useMemo(
    () =>
      data.roomsList.filter(({ roomData: { processingStatus } }) =>
        !props.showComplete
          ? processingStatus !== 'abgeschlossen'
          : processingStatus === 'abgeschlossen'
      ),
    [data, props.showComplete]
  );

  const options = {
    filterType: 'textField',
    sort: true,
    rowsPerPage: props.rows,
    caseSensitive: true,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    searchable: false,
    textLabels: muiTableTextLabels,
    responsive: 'vertical',
    selectableRows: false,
    selectableRowsOnClick: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
  };

  const showSelectedDays = (items) => {
    let weekdayString = '';
    const weekday = new Array(7);
    weekday[0] = 'Sonntag';
    weekday[1] = 'Montag';
    weekday[2] = 'Dienstag';
    weekday[3] = 'Mittwoch';
    weekday[4] = 'Donnerstag';
    weekday[5] = 'Freitag';
    weekday[6] = 'Samstag';
    items.forEach((day, index) => {
      if (day) {
        weekdayString = weekdayString + ' ' + weekday[index];
      }
    });
    return weekdayString;
  };

  return (
    <Fragment>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <Spinner name="line-scale" color={stylingData.colors.color3} />
      </Backdrop>
      <MUIDataTable
        title={!props.showComplete ? 'Offene Beratungen' : 'Abgeschlossene Beratungen'}
        data={filteredRoomsList.map((item) => [
          item.roomData.consultationTitle,
          `${
            item.roomData.repeating && item.roomData.kindOfRepeating === 'daily'
              ? 'täglich'
              : item.roomData.repeating && item.roomData.kindOfRepeating === 'daily_workingweek'
              ? 'täglich (Mo-Fr)'
              : item.roomData.repeating && item.roomData.kindOfRepeating === 'weekly'
              ? `jeden ${showSelectedDays(item.roomData.selectedDays)}`
              : item.roomData.consultationDate.split('-')[0]
          } `,

          item.roomData.consultationTime,
          item.roomData.processingStatus,
        ])}
        columns={columns}
        options={options}
      />

      {showDialog && !falseLogin && <RoomFunctions onClose={() => setShowDialog(false)} />}
      {roomToDelete !== null && roomToDelete !== undefined ? (
        <DeleteRoom
          deleteRoomDialog={deleteRoomDialog}
          setdeleteRoomDialog={setdeleteRoomDialog}
          handleDelete={handleDelete}
          roomToDelete={roomToDelete}
        />
      ) : null}
    </Fragment>
  );
}
