import React, { useState, useContext, useEffect, useMemo } from 'react';
import Main from './components/Main';
import { getData, getApiToken, getClientApiToken, getContracts } from './helper/getData';
import { ThemeProvider } from '@material-ui/core/styles';
import { ApellaContext } from './context/ApellaContext';
import { LoginContext } from './context/LoginContext';
import { StylingContext } from './context/StylingContext';
import { createMuiTheme } from '@material-ui/core/styles';
import { UrlContext } from './context/UrlContext';

import Landingpage from './components/clientComponents/Landingpage';

const loadUrlList = async (stage, companyIdentification) => {
  const url = process.env.REACT_APP_DELIVERFIRSTREQUESTURLS;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      stage: stage,
      companyId: companyIdentification,
    }),
  };
  async function fetchData() {
    const response = await fetch(url, options);
    let urlListJson = await response.json();
    return urlListJson.Item;
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    return console.log(error);
  }
};

const App = (props) => {
  const stylingData = useContext(StylingContext);
  const urlData = useContext(UrlContext);
  const loginData = useContext(LoginContext);
  const [loadingUrl, setloadingUrl] = useState(true);
  const [fault, setFault] = useState(true);

  useEffect(() => {
    setloadingUrl(true);
    const fetchData = async () => {
      let urls = await loadUrlList(props.stage, props.companyIdentification);
      await urlData.seturlList(urls);
      return urls;
    };
    fetchData();
    setloadingUrl(false);
  }, []); // eslint-disable-line

  const [palette, setpalette] = useState({
    primary: {
      main: stylingData.colors.color2,
      contrastText: '#ffffff',
    },
    secondary: {
      main: stylingData.colors.color3,
      contrastText: '#ffffff',
    },
  });

  let ApellaTheme = useMemo(
    () =>
      createMuiTheme({
        root: {
          display: 'flex',
        },
        control: {
          spacing: 0,
        },
        palette: palette,
      }),
    [palette]
  );

  const contextData = useContext(ApellaContext);

  const urlParams = new URLSearchParams(window.location.search);
  const roomPassword = urlParams.get('roomPassword');

  useEffect(() => {
    if (!loadingUrl) {
      const faultLoginTest = async () => {
        const getDataResult = await getData({
          companyIdentification: props.companyIdentification,
          contextID: contextData.contextID,
          otpKey: contextData.otpKey,
          stage: props.stage,
          urlParam:
            urlData.urlList.connectToApellaBaseurl +
            urlData.urlList.connectToApellaApiEndpoints.getApellaAgentData.endpoint,
        });
        if (getDataResult === -1) {
          setFault(true);
        } else {
          setFault(false);
          loginData.setAgent(true);
          var agentData = {
            id: getDataResult.response1.AppLogin.AppLogin.Vermittler.ApellaVermID,
            company: getDataResult.response1.AppLogin.AppLogin.Vermittler.Firma,
            agentData: {
              firstName: getDataResult.response1.AppLogin.AppLogin.Vermittler.Vorname,
              lastName: getDataResult.response1.AppLogin.AppLogin.Vermittler.Name,
              email1: getDataResult.response1.AppLogin.AppLogin.Vermittler.Email,
              photo: getDataResult.response1.AppLogin.AppLogin.Vermittler.FotoUrl,
            },
          };

          var clientData = {
            id: getDataResult.response2.SearchKunde.Kunde[0].AdrID,
            /* company: getDataRescult.response1.AppLogin.AppLogin.Vermittler.Firma, */
            clientData: {
              firstName:
                getDataResult.response2.SearchKunde.Kunde[0].Vorname == null
                  ? ''
                  : getDataResult.response2.SearchKunde.Kunde[0].Vorname,
              lastName:
                getDataResult.response2.SearchKunde.Kunde[0].Name == null
                  ? ''
                  : getDataResult.response2.SearchKunde.Kunde[0].Name,
              email1: getDataResult.response2.SearchKunde.Kunde[0].EMail1,
              email2: getDataResult.response2.SearchKunde.Kunde[0].EMail2,
              phone1: getDataResult.response2.SearchKunde.Kunde[0].Telefon1,
              phone2: getDataResult.response2.SearchKunde.Kunde[0].Telefon2,
              phone3: getDataResult.response2.SearchKunde.Kunde[0].Telefon3,
              photo: getDataResult.response2.SearchKunde.Kunde[0].FotoUrl,
            },
          };
          let colors = {};
          let regex = /#([a-f0-9A-F]{6}|[a-f0-9A-F]{3})$/;
          console.log(
            'regex',
            regex.test(getDataResult.response1.AppLogin.AppLogin.Vermittler.Color1)
          );
          if (
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color1 !== '' &&
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color1 !== null &&
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color1 !== undefined &&
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color1 !== 'unknown' &&
            regex.test(getDataResult.response1.AppLogin.AppLogin.Vermittler.Color1)
          ) {
            colors.color1 = getDataResult.response1.AppLogin.AppLogin.Vermittler.Color1;
          } else colors.color1 = '#dde8f1';
          if (
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color2 !== '' &&
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color2 !== null &&
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color2 !== undefined &&
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color2 !== 'unknown' &&
            regex.test(getDataResult.response1.AppLogin.AppLogin.Vermittler.Color2)
          ) {
            colors.color2 = getDataResult.response1.AppLogin.AppLogin.Vermittler.Color2;
          } else colors.color2 = '#196591';
          if (
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color3 !== '' &&
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color3 !== null &&
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color3 !== undefined &&
            getDataResult.response1.AppLogin.AppLogin.Vermittler.Color3 !== 'unknown' &&
            regex.test(getDataResult.response1.AppLogin.AppLogin.Vermittler.Color3)
          ) {
            colors.color3 = getDataResult.response1.AppLogin.AppLogin.Vermittler.Color3;
          } else colors.color3 = '#d5bf7c';

          var contractResults = await getContracts({
            companyIdentification: contextData.companyIdentification,
            contextID: contextData.contextID,
            otpKey: contextData.otpKey,
            stage: props.stage,
            agentId: agentData.id,
            clientId: clientData.id,
            urlParam:
              urlData.urlList.connectToApellaBaseurl +
              urlData.urlList.connectToApellaApiEndpoints.getApellaContracts.endpoint,
          });

          clientData.clientData.title =
            contractResults.response.CheckKundeEx.CheckKundeExResponse.Kunde.Anrede;
          clientData.contracts =
            contractResults.response.CheckKundeEx.CheckKundeExResponse.Kunde.Vertrag;

          contextData.setagentData(agentData);
          contextData.setclientData(clientData);
          stylingData.setcolors(colors);
        }

        if (contextData.otpKey) {
          const apiToken = await getApiToken({
            companyIdentification: props.companyIdentification,
            contextID: contextData.contextID,
            otpKey: contextData.otpKey,
            stage: props.stage,
            urlParam:
              urlData.urlList.connectToApellaBaseurl +
              urlData.urlList.connectToApellaApiEndpoints.getApiToken.endpoint,
          });
          contextData.setapiToken(apiToken);
        }
        if (roomPassword) {
          const apiToken = await getClientApiToken({
            roomPassword: roomPassword,
            urlParam:
              urlData.urlList.connectToApellaBaseurl +
              urlData.urlList.connectToApellaApiEndpoints.getClientApiToken.endpoint,
          });
          contextData.setapiToken(apiToken);
        }
      };
      faultLoginTest();
    }
  }, [roomPassword, props.companyIdentification, contextData.otpKey, urlData.urlList]); // eslint-disable-line

  useEffect(() => {
    if (
      stylingData.colors !== undefined &&
      stylingData.colors.color1 !== undefined &&
      stylingData.colors.color2 !== undefined &&
      stylingData.colors.color3 !== undefined &&
      stylingData.colors.color1 !== '' &&
      stylingData.colors.color2 !== '' &&
      stylingData.colors.color3 !== '' &&
      stylingData.colors.color1 !== null &&
      stylingData.colors.color2 !== null &&
      stylingData.colors.color3 !== null
    ) {
      setpalette({
        primary: {
          main: stylingData.colors.color2,
          contrastText: '#ffffff',
        },
        secondary: {
          main: stylingData.colors.color3,
        },
        error: {
          main: stylingData.colors.color3,
          contrastText: '#ffffff',
        },
        success: {
          main: stylingData.colors.color2,
        },
        warning: {
          main: stylingData.colors.color3,
          contrastText: '#ffffff',
        },
      });
    }
  }, [stylingData.colors]);

  return fault ? (
    contextData.otpKey && fault ? null : (
      <ThemeProvider theme={ApellaTheme}>
        <Landingpage
          roomPassword={roomPassword}
          loadingUrl={loadingUrl}
          loadUrlList={loadUrlList}
          stage={props.stage}
        />
      </ThemeProvider>
    )
  ) : (
    <ThemeProvider theme={ApellaTheme}>
      <Main />
    </ThemeProvider>
  );
};

export default App;
